<template>
    <div class="ProfileBox">
        <h2>{{ this.risorse["IndirizziDestinazione"] }}</h2>
        <div class="Form">
            <div class="FormRow" v-for="(indirizzo, index) in this.$store.getters.getDestinazioni" :key="index">
                <div class="FormBoxWide">
                    <div class="UserInfo"><span>{{ this.risorse["Indirizzo"] }} {{ index+1 }}</span> <span> {{indirizzo.Indirizzo}}, {{indirizzo.Cap}}  {{indirizzo.Citta}} {{indirizzo.Provincia ? ", "+indirizzo.Provincia : ""}} </span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {risorseMixin} from "@/mixin.js";

export default {
    name: "IndirizziSpedizione",
    mixins:[risorseMixin]
}
</script>